


.containerChk {
  justify-content: space-between; /* Distribuye los paneles izquierdo y derecho */
  gap: 10px; /* Espacio entre los dos paneles */
}

.left-panel2, .right-panel2 {
  flex: 1; /* Ambos paneles ocupan espacio igual */
  padding: 10px;
  border: 1px solid #ddd; /* Opcional: para distinguir los paneles */
  border-radius: 8px;
}

.left-panel2 .field-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre etiqueta y campo */
}


.left-panel2 .field-container label {
  min-width: 100px; /* Ajusta según el espacio necesario */
  text-align: left;
}

.left-panel2 input {
  flex: 1; /* Permite que el campo de texto crezca según sea necesario */
}

.left-panel2 {
  width: 60%;
 
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacio entre los elementos */
}

.right-panel2 {
  width: 40%;
  height: auto;
  flex-direction: column;
  gap: 10px;
}

textarea {
  width: 100%; /* Ajusta el ancho del textarea */
  resize: vertical; /* Permite cambiar el tamaño verticalmente si es necesario */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Botones alineados a la derecha */
}

.add-cards-btn {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.btn-cambiar
{
  padding: 2px 10px;
  background-color: #9dff00;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.btn-browse
{
  padding: 5px 15px;
  background-color: #ffea00;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
.btn-update
{
  padding: 5px 15px;
  background-color: #ff9100;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-update-progress
{
  padding: 5px 15px;
  background-color: #303030;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-update:hover
{

  background-color: #d28f00;

}
.btn-browse:hover
{

  background-color: #aa9c01;

}




.add-cards-btn:hover {
  background-color: #0056b3;
}


.table-container {
  max-height: 450px; /* Cambia esta altura según lo necesario */
  overflow-y: auto; /* Aplica el scroll aquí */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.table-container3 {
  max-height: 300px; /* Cambia esta altura según lo necesario */
  overflow-y: auto; /* Aplica el scroll aquí */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.table-permission {
  max-height: 300px; /* Cambia esta altura según lo necesario */
  overflow-y: auto; /* Aplica el scroll aquí */
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

table {
  width: 100%; /* Asegura que la tabla ocupe el 100% del contenedor */
  border-collapse: collapse;
}

thead th {
  position: sticky; /* Mantén los encabezados fijos */
  top: 0;
  background-color: #f4f4f4;
  z-index: 2;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th:last-child, td:last-child {
  width: 30%;
}

.table-container3 table td {
  width: 100%;
  cursor: pointer;
}
