/* Contenedor principal */
.container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  /* Dropdown */
  .dropdown {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }
  
  .dropdown-select {
    width: 200px;
    padding: 10px;
    font-size: 16px;
  }

  .newModel {
    margin-left: 200px;
    display: flex;
    font-size: 16px;
  }
  
  /* Tabla */
  .table-container {
    min-height: 250px;
    max-height: 250px; /* Altura fija de la tabla */
    overflow-y: auto; /* Scroll vertical si el contenido excede la altura fija */
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .version-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .version-table th,
  .version-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .version-table th {
    background-color: #f4f4f4;
    position: sticky;
    top: 0;
  }
  
  /* Nueva versión */
  .new-version {
    margin-top: 20px;
  }
  
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .form-group label {
    margin-right: 10px;
    width: 120px;
  }
  
  .input,
  .textarea {
    padding: 8px;
    font-size: 14px;
    flex: 1;
    margin-right: 10px;
  }
  
  .textarea {
    height: 100px;
  }
  
  .file-input {
    padding: 8px;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  