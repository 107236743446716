.containerChk {
  padding: 20px;
  width: 100%;
}

.checkpoints-list{
  max-height: 500px; /* Define la altura máxima del contenedor */
  overflow-y: auto;  /* Agrega scroll vertical si el contenido excede la altura */
}
.kr-head{
  background-color: #7197ee;
}
.cards-list{
  width: 100%;
  max-height: 600px; /* Define la altura máxima del contenedor */
  overflow-y: auto;  /* Agrega scroll vertical si el contenido excede la altura */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.new-checkpoint {
  
  align-items: center;
  justify-content: space-between;
}

.new-checkpoint select {
  width: 60%;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.add-cards-btn {
  width: 100%;
  background-color: #5800ff;
}

.add-cards-btn:hover {
  background-color: #8f53ff;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
