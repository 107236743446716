/* Contenedor principal */
.containerVersions {
    width:80vw;
    margin: 10px;
    font-family: Arial, sans-serif;
  }
  
  /* Dropdown */
  .dropdown {
    gap: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }
  
  .dropdown-select {
    width: 250px;
    padding: 20px;
    font-size: 16px;
  }
  
  /* Tabla */
  .table-container {
    min-height: 250px;
    max-height: 250px; /* Altura fija de la tabla */
   
    overflow-y: auto; /* Scroll vertical si el contenido excede la altura fija */
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .version-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .version-table th,
  .version-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .version-table th {
    background-color: #f4f4f4;
    position: sticky;
    top: 0;
  }
  
  /* Nueva versión */
  .new-version {
    margin-top: 20px;
  }
  
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .form-group label {
    margin-right: 10px;
    width: 120px;
  }
  
  .input,
  .textarea {
    padding: 8px;
    font-size: 14px;
    flex: 1;
    margin-right: 10px;
  }
  
  .textarea {
    height: 50px;
  }
  
  .file-input {
    padding: 8px;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  

  .card {
    background: #f8fbe9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80vw;
    text-align: left; /* Asegura alineación izquierda en todo el contenido */
  }

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0px;
    text-align: left;
  }

  .section {
    margin-bottom: 15px;
  }

  .row {
    display: flex;
    align-items: center; /* Asegura alineación vertical */
    gap: 10px; /* Espacio uniforme entre elementos */
    justify-content: flex-start; /* Asegura alineación izquierda */
    width: 100%;
  }

  .label {
    text-align: left;
  }

  .select {
    width: 220px; /* Ancho uniforme */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 100px;
  }

  .react-select-container {
    min-width: 300px;
  }
  .button-green {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    width: 200px; /* Ancho uniforme */
  }

  .button-green:hover {
    background-color: #45a049;
  }

  .button-yellow {
    background-color: #f1c40f;
    color: black;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
  }

  .button-yellow:hover {
    background-color: #d4ac0d;
  }

  