.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background-color: #5F5AFA;
    box-shadow: 0 2px 4px #0000001a;
  }

  .usr_name{
    color: #fafafa;
  }
  .logout-button {
    background-color: #fafafa;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px 10px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #fffcde;
  }