

.cards-list2 tr {
  cursor: move;
}


.button-container{
  padding-top: 5px;
}

.right-panel2 {
  padding: 10px;
  width: 90%;
  border: 0;
}

table {
  width: 100%;
  margin-bottom: 10px;
}

.cards-list2 td {
  font-size: 21px;
  background-color: #f8f8e0;
  padding: 6px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: black;

}



.cards-list2 th {
  background-color: #f4f4f4;
}


button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
