.container {

  padding: 10px;
  text-align: left;
  font-family: Arial, sans-serif;
}

.containerChk {
  display: flex;
  justify-content: left;
  padding: 10px;
  width: 90%;
  gap: 10px; /* Espacio entre las columnas */
}

#p {
  text-align: center;
  size: 10px;
}
.search-bar {
  margin-bottom: 20px;
}

.search-bar label {
  margin-right: 10px;
}

.search-bar input {
  width: 200px;
  padding: 5px;
}

.search-bar button {
  padding: 5px 10px;
  margin-left: 10px;
}

.annotations {
  border: 1px solid #ccc;
  padding: 10px;
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;
}

.annotation {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.left-panel {
  flex: 4; /* Ocupará 2 partes del espacio */
  padding: 10px;
  background-color: #f5f5f5; /* Fondo claro para resaltar */
}

.right-panel {
  flex: 3; /* Ocupará 1 parte del espacio */
  padding: 10px;
}

.header {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  gap: 10px;
  align-items: center;
}

.aditional-info {
  gap: 20px;
  margin: 10px;
}

.status-dot-red {
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 0px;
}

.status-dot-green {
  background-color: rgb(0, 255, 60);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 0px;
}


.new-annotation {
  margin-top: 20px;
}

.new-annotation textarea {
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  padding: 10px; /* Espacio interno dentro del textarea */
  margin-bottom: 10px; /* Espacio debajo del textarea */
  box-sizing: border-box; /* Incluye el padding dentro del ancho total */
}

.new-annotation select {
  margin-right: 10px;
  padding: 5px;
}

.new-annotation button {
  padding: 5px 10px;
}
