.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    max-width: 90%;
}

.close-button {
    position: absolute;
    color: white;
    background: brown;
    top: 10px;
    right: 10px;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.close-button:hover {

    background: rgb(235, 57, 57);

}
