.containerDashboard {
  display: flex;
  align-items: center;
  flex-direction: column; /* Asegura que las filas se apilen una debajo de la otra */
  gap: 16px; /* Espaciado opcional entre filas */
}

.DashboardsRow {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos dentro se ajusten si hay muchos */
  gap: 16px; /* Espaciado opcional entre tarjetas */
}
.carddash {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-box2 {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  overflow: hidden; /* Evita que la imagen sobresalga */
}

.image-box2 img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen sin deformarla */
}

.title2 {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
