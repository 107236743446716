.sidebar {
  width: 250px;
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.sidebar h2 {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #333;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  margin-bottom: 10px;
}

.menu-title {
  background-color: #e6e6e6;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  color: #333;
  border-radius: 4px;
}

.menu-title:hover {
  background-color: #e0ffa8;
}

.submenu {
  text-align: left;
}

.submenu li {
  padding: 8px 0;
  color: #555;
}

.submenu li:hover {
  color: #000;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

  