.containerChk {
  padding: 0px;
  padding-left: 2px;
  width: 100%;
  height: 100%;
  
}

.button-container{
  padding-top: 5px;
}

.right-panel2 {
  padding: 0px;
  width: 95%;
  height: 100%;
  border: 0;
}

.krtable {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background-color: antiquewhite;
}

.numbersRow{
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

.selectedTable th, td {
  border: 0px;
  padding: 6px;
  text-align: left;
}


button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
