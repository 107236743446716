.containerChk {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.left-panel2, .right-panel2 {
  border: 1px solid #000;
  padding: 10px;
}
.selected{
  background-color: #d3e0ff;
}
.checkpoints-list{
  max-height: 200px; /* Define la altura máxima del contenedor */
  overflow-y: auto;  /* Agrega scroll vertical si el contenido excede la altura */
}
.kr-head{
  background-color: #7197ee;
}

.cards-list3{
  max-height: 70vh; /* Define la altura máxima del contenedor */
  overflow-y: auto;  /* Agrega scroll vertical si el contenido excede la altura */
}

.left-panel3 {
  width: 30%;
}

.right-panel3 {
  width: 70%;
  height: 100vh;
}

.bt-function{
  padding: 10px;
  margin: 5px;
  width: 100%;

}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}


.new-checkpoint {
  
  align-items: center;
  justify-content: space-between;
}

.new-checkpoint select {
  width: 60%;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.add-cards-btn {
  width: 100%;
  background-color: #5800ff;
}

.add-cards-btn:hover {
  background-color: #8f53ff;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
