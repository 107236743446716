

/* Dropdown */
.dropdown {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

.dropdown-select {
  width: 200px;
  padding: 10px;
  font-size: 16px;
}

/* Tabla */
.table-container2 {
  width: 100%;
  min-height: 250px;
  max-height: 500px; /* Altura fija de la tabla */
  overflow-y: auto; /* Scroll vertical si el contenido excede la altura fija */
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.version-table2 {
  width: 100%;
  border-collapse: collapse;
}



.newProject{
  padding: 20px;
  text-align: left;
}
.newProject button{
  padding: 0px;
  margin-left: 10px;
  width: 100px;
  height: 30px;
  text-align: center;
}

.newProject input{
 height: 30px;
 width: 300px;
}


.version-table2 tr:hover {
  background-color: #e1ebe1; /* Darker green */
}


.version-table2 th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
}


