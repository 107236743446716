.App {
    display: flex;
    flex-direction: column;
  }
  
  .toggle-sidebar {
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    z-index: 1000;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .toggle-sidebar:hover {
    background-color: #45a049; /* Darker green */
  }
  
  .main-content {
    display: flex;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f4f4f4;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  .pin-generator {
    flex: 1;
    padding: 20px;
  }
  