.container {
    padding: 20px;
    text-align: left;
    font-family: Arial, sans-serif;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-bar label {
    margin-right: 10px;
  }
  
  .search-bar input {
    width: 200px;
    padding: 5px;
  }
  
  .search-bar button {
    padding: 5px 10px;
    margin-left: 10px;
  }
  

  .annotation {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .status {
    display: flex;
    align-items: center;
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
  }
  
  .new-annotation {
    margin-top: 20px;
  }
  
  .new-annotation textarea {
    margin-right: 10px;
    padding: 5px;
    width: 100%;
    resize: vertical; /* Permite ajustar el tamaño verticalmente */
  }
  
  .new-annotation select {
    margin-right: 10px;
    padding: 5px;
  }
  
  .new-annotation button {
    padding: 5px 10px;
  }
  